@import "../../design-tokens/css-variables.scss";

.instructors {
    color: var(--color-additional);
    background-color: var(--color-primary);
    width: 100%;
    text-align: center;

    .instructors-container {                
        .instructor-kendo {
            padding-left: 5vw;            
        }    
    
        .instructor-iaido {
            padding-left: 5vw;
        }
    
        .instructor-naginata {
            padding-left: 5vw;
        }
    
        .instructor-dan{
            font-size: 1.5rem;
            padding: 0.7em 0;
            border-bottom: 0.1px solid var(--color-additional);
        }
     
        .instructor-type {
            margin-top: 0;
            padding-top: 0.5rem;
            text-decoration: underline;
        }
    
        .instructor-title {
            margin-top: 0;
            padding: 1em 0; 
        }
    
        
    
        .instructor-card-container{
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            //flex-basis: 3rem;
            
            .instructor-card{
                padding: 0 1em;
            }
    
        }
    
        .instructor-photo{
            max-width: 100px;
            margin: 0;
            padding: 0;
        }
    
        .instructor-name {
            font-size: 1rem;
            margin: 0;
            padding: 0;
        }
    }    
}

@media screen and (min-width: 800px) {
    .instructors{
        padding-left: 0;
        margin-left: 0;
        display: flex;
        justify-content: center;
        .instructors-container {
            width: 100%;
            max-width:1400px;
            .instructor-type{
                font-size: 2.5rem;
                text-align: left;
                padding-top: 1em;
                padding-bottom: 1em;
            }
    
            .instructor-dan {
                display: grid;
                grid-template-columns: 1fr 3fr 1fr;
                opacity: 1;
                
                .instructor-dan-name {
                    text-align: left;
                    // padding-left: 1em;
                    grid-column: 1 / 2;                    
                }
    
                .instructor-card-container{
                    text-align: center;
                    grid-column: 2 / 3;            
                }
            }        
        }
        
    }
    
}