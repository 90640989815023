@import "../../design-tokens/css-variables.scss";

.contactUs {
    background: var(--color-secondary);
    color: var(--color-primary);
    text-align: center;
    width: 100%;

    .contactUs-container {
        width: 90%;
        max-width: 400px;
        margin: auto;

        .contactUs-form {
            text-align: left;
            margin-bottom: 1em;

            label {
                font-size: 1.5rem;

            }

            input {
                background-color: var(--color-additional);
                display: block;
                width: 100%;
                line-height: 1.5rem;
                border: none;
            }
    
            textarea {   
                background-color: var(--color-additional);
                width: 100%;
                border: none;
            }
            
            .contactUs-error {
                padding: 0;
                margin: 0;
                color: yellow;
            }
        }        

        button {
            font-size: 1.5rem;
            background-color: var(--color-primary);
            font-weight: bold;
            color: var(--color-additional);  
            width: 100%;
            margin-bottom: 1em;
            outline: none;
            border: none;
            cursor: pointer;
            box-shadow: inset 0 0 0 0 var(--color-additional);
            transition: ease-out 150ms;

            &:hover {
                color: var(--color-primary);
                box-shadow: inset 400px 0 0 0 var(--color-additional);
            }
        }    
    }    
}