@import "../../design-tokens/css-variables.scss";

.upcomingEvent {
    background-color: var(--color-additional);    
    color: var(--color-primary);
    width: 100%;
    padding: 1rem;
    text-align: center;

    &:focus {
        background: red;
    }

    .upcomingEvent-container {
        padding-left: 5vw;

        .upcomingEvent-title {
            margin-top: 0;
            padding: 1em 0;       
        }
    
        .upcomingEvent-content {
            font-size: 1.5rem;
            margin: 0;            
            text-align: left;
            line-height: 2rem;
        }
        
        .upcomingEvent-subcontent {
            font-size: 1rem;            
        }
    }
    
}