@import "../../design-tokens/css-variables.scss";

.landing {
  background-image: url("../../assets/images/background2.jpg");
  background-position: center bottom; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */

  color: var(--color-additional);
  width: 100%;
  text-align: center;

  .landing-left {
    background-color: var(--color-primary);
    width: 100%;
    opacity: 0.85;

    .landing-title {
      font-size: 3rem;
      font-weight: bold;
      padding-top: 0.5em;
      margin: 0;
    }

    .landing-title1 {
      color: var(--color-secondary);
    }

    .landing-title2 {
      padding-top: 0;
    }

    .landing-subtitle {
      font-size: 1.5rem;
    }

    .landing-btn {
      font-size: 1rem;
      margin-top: 1em;
      margin-bottom: 1em;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      color: var(--color-primary);
      font-weight: bold;
      width: 17rem;
      border: none;
      border-radius: 0.2em;
      box-shadow: inset 0 0 0 0 var(--color-secondary);
      transition: ease-in-out 150ms;

      &:hover {
        cursor: pointer;
        box-shadow: inset 17rem 0 0 0 var(--color-secondary);
        color: var(--color-additional);
      }
    }

    .landing-btn-2 {
      font-size: 0.7rem;
    }
  }

  .landing-right {
    background-color: var(--color-primary);
    opacity: 0.85;
    .landing-video {
      width: 90%;
      height: calc(90vw / 560 * 315);
      opacity: 1;
      z-index: 2;
      margin-top: 2em;
      margin-bottom: 2em;
    }
  }
}

@media screen and (min-width: 800px) {
  .landing {
    width: 100%;
    min-height: 50vmin;
    display: flex;

    .landing-left {
      width: 45%;
      opacity: 1;

      .landing-left-container {
        padding-left: 5vw;
        margin-top: 5em;
        margin-bottom: 5em;
        text-align: left;
        .landing-title {
          font-size: 2.5rem;
        }

        .landing-subtitle {
          font-size: 1rem;
          line-height: 2em;
        }

        .landing-btn {
          width: 13rem;
          border: none;
          box-shadow: inset 0 0 0 0 var(--color-secondary);
          transition: ease-in-out 150ms;

          &:hover {
            cursor: pointer;
            box-shadow: inset 13rem 0 0 0 var(--color-secondary);
            color: var(--color-additional);
          }
        }
      }
    }

    .landing-right {
      width: 55%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.85;
      z-index: 0;

      .landing-right-container {
        width: 90%;
        height: 90%;
        opacity: 1;
        z-index: 999;
        position: relative;
        .landing-video {
          position: absolute;
          width: 100%;
          margin: 0;
          height: 100%;
          left: 0;
          top: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .landing {
    .landing-left {
      .landing-left-container {
        margin-top: 3em;
        margin-bottom: 1em;
        .landing-title {
          font-size: 4rem;
        }

        .landing-subtitle {
          font-size: 1.5rem;
        }

        .landing-btn {
          width: 17rem;
          border: none;
          box-shadow: inset 0 0 0 0 var(--color-secondary);
          transition: ease-in-out 150ms;

          &:hover {
            cursor: pointer;
            box-shadow: inset 17rem 0 0 0 var(--color-secondary);
            color: var(--color-additional);
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .landing {
    .landing-left {
      .landing-left-container {
        .landing-title {
          font-size: 5rem;
        }

        .landing-btn {
          font-size: 1.2rem;
          width: 22rem;
          padding-top: 1em;
          padding-bottom: 1em;
          border: none;
          box-shadow: inset 0 0 0 0 var(--color-secondary);
          transition: ease-in-out 150ms;

          &:hover {
            cursor: pointer;
            box-shadow: inset 22rem 0 0 0 var(--color-secondary);
            color: var(--color-additional);
          }
        }
      }
    }

    // .landing-right{

    //         .landing-video {

    //             width: calc(0.8*min(70vw,100vh));
    //             height: calc(min(70vw,100vh) * 0.8 / 560 *315);
    //             // max-width: 784px;
    //             // max-height: 100%;
    //         }
    // }
  }
}
