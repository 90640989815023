@import "../../design-tokens/css-variables.scss";

.navbar {
    background-color: var(--color-secondary);
    color: var(--color-primary);
    text-align: center;
    width: 100%;    

    img {
        // position: absolute;
         margin-left: 0.5em;
         width: 5rem;
    }

    .nav-toggle {
        display: none;
    }

    .nav-toggle:checked ~ nav {
        transform: scale(1,1);
    }

    .nav-toggle:checked ~ nav li {
        opacity: 1;
        transition: opacity 250ms ease-in-out 250ms;
    }

    .nav-toggle-label {
        position: absolute;
        right: 0;
        margin-right: 1em;
        margin-top: 1em;
        
    }


    .navbar-btns {               
        position: absolute;
        z-index: 999;
        text-align: left;
        background-color: var(--color-secondary);
        color: var(--color-primary);
        width: 100%;
        transform: scale(1,0);
        transform-origin: top;
        transition: transform 300ms ease-in-out;
        
        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                margin: 0.75em;
                text-decoration: none;                
                opacity: 0;
                transition: opacity 150ms ease-in-out;

                a {
                    color: var(--color-primary);
                    text-decoration: none;

                    &:hover {
                        color: var(--color-additional);
                    }
                }
            }
        }

        .navbar-btn {

            &:hover {
                color: var(--color-additional);
            }
         }         
    }
}

@media screen and (min-width: 945px) {
    .nav-toggle-label {
        display: none;
    }

    .navbar {
        display: grid;
        grid-template-columns: minmax(80px, 1fr) auto minmax(860px, 3fr) 1fr;
        font-size: 1.1rem;

        img {
            grid-column: 1 / 2;            
        }

        .navbar-btns {
            all: unset;
            grid-column: 2 / 4;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            
            ul {
                display: flex;
                justify-content: flex-end;
                li {
                    
                    opacity: 1;
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 4px;
                        background: var(--color-additional);
                        position: absolute;
                        bottom: -1em;
                        transform: scale(0, 1);
                        transition: transform ease-in-out 250ms;
                    }
                    
                    &:hover:before {
                        transform: scale(1,1);
                    }


                    a {
                        color: var(--color-primary);
                        text-decoration: none;

                        &:hover {
                            color: var(--color-additional);
                        }
                    }
                }

                

                
            }
            
        }
    }
}
