@import "../../design-tokens/css-variables.scss";

.pricing {
    background-color: var(--color-primary);
    width: 100%;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 1em;
    text-align: center;

    .pricing-container {
        .pricing-title {
            color: var(--color-additional);        
        }
    
        .pricing-content {
            background-color: var(--color-primary);
            width: 96%;
            text-align: center;
            margin: auto;
            padding: auto;
        }
    
        .pricing-section {
             background-color: var(--color-primary);
             width: 100%;
             text-align: center;
            
            .membertype {
                background-color: var(--color-additional);
                color: var(--color-primary);
                margin-bottom: 0.7em;
                padding-bottom: 0.5em;
                max-width: 300px;
                margin-left: auto;
                margin-right: auto;
            }
    
            h3 {
                margin: 0;
                padding: 0.4em 0;
                text-transform: uppercase;
                background-color: var(--color-secondary);
            }
    
            p {
                margin: 0;
            }
        }
    
        .pricing-note {
            font-size: 0.9rem;
            margin-left:auto;
            margin-right: auto;
            width: 95%;     
            text-align: left;
            color: var(--color-additional);
        }
    }
    
   
  }

  @media screen and (min-width: 800px) {
      .pricing {
          display: flex;
          justify-content: center;
          .pricing-container {
              width:80%;
            //   max-width: 1600px;
                .pricing-content {
                    width: 80%;
                    .pricing-section {
                        
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;                

                        .membertype {
                            min-width: 270px;
                            line-height: 1.5em;
                        }                                
                    }

                    .pricing-note {
                        max-width: 1000px;
                        font-size: 1.1rem;
                    }

                }
        }
        
      }
}