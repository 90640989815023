@import "../../design-tokens/css-variables.scss";

.schedule {
    text-align: center;
    background-color: var(--color-additional);
    width: 100%;
    margin-top: 0;
    padding-top: 0;
    display: flex;
    justify-content: center;

    .schedule-container {

        .schedule-title {
            margin-top: 0;
            padding: 1em 0;
        }
    
        table, th, td {        
            border: 1px solid #B1A9A9;
            border-collapse: collapse;
        }
    
        table {
            margin-left: auto;
            margin-right: auto;
            max-width: 96%;
            th {
                background-color: #C4C4C4;
                width: 5rem;
                height: 3rem;
            }
        
            td{
                font-size: 0.8rem;
                height: 3rem;                 
            }
    
            .odd {
                background-color: #E3DFDF;
            }
    
            .even {
                background-color: #ECE6E6;
            }
    
            .TUE{
                display: none;
            }
    
            .FRI {
                display: none;
            }
    
            .SUN {
                display: none;
            }                
        }
        
        .schedule-note {
            width: 95%;
            text-align: left;
            padding-left: 1em;
            margin-bottom: 0;
            font-size: 0.8rem;
            color: var(--color-secondary);
            padding-top: 1em;
            padding-bottom: 1.5em;
    
            div {
                text-align: left;
                padding-top: 0.5em;
            }
        }
    }
    
  }

  @media screen and (min-width: 800px) {
    .schedule {
        .schedule-container {
            padding-left: 5vw;
            max-width: 900px;
            
            table {
                border: 1px solid #B1A9A9;
                border-collapse: collapse;

                th, td{
                    font-size: 1.2rem;
                    height: 3em;
                }

                .TUE{
                    display: table-cell;
                }

                .FRI {
                    display: table-cell;
                }

                .SUN {
                    display: table-cell;
                }                             
            }

            .schedule-note {
                text-align: center;
                margin: auto;
                padding: auto;
                font-size: 1.2rem;
                color: var(--color-secondary);                        
            }
    }
}
}