@import "../../design-tokens/css-variables.scss";

header {
    background: var(--color-primary);
    color: var(--color-additional);
    text-align: center;
    width: 100%;
    padding: 0.5em 0;    

    .header-container {
        text-align: left;         

        section {
            padding-left: 1em;
            .header-item {
                display: flex;
                a {
                    color: var(--color-additional);
                    text-decoration: none;                    
                }

                i {
                    padding-right: 0.5em;
                }
            }

                
        }
        .header-email, .header-social-media {
            color: var(--color-secondary);
        }            
    }
}

@media screen and (min-width: 945px) {
    header {
        display: flex;
        justify-content: center;
        .header-container {
                all: unset;           
                display: flex;
                justify-content: space-between;
                align-items: center;
                max-width: 1200px; 
                section {
                    display: flex;
                }        

                .header-item {
                    display: flex;
                    align-items: center;
                    
                    p {
                        padding-right: 2em;
                    }
                }

                .header-email,
                .header-phone,
                .header-address {               
                        border-right: 2px solid var(--color-secondary);                                               
                }

                .header-item + .header-item {
                    padding-left: 2em;                    
                    // opacity: 1;
                    // position: relative;
                }                                
            }           
        }
}
