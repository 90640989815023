@import "../../design-tokens/css-variables.scss";

.aboutUs {
    background: var(--color-addtional);
    color: var(--color-primary);
    text-align: center;
    width: 100%;

    .aboutus-container{

        padding-left: 0.5em;
        padding-right: 0.5em;

        .text-container{

            font-size: 0.5 rem;
            text-align: left;            
        }

        .aboutus-btn {
            font-size: 1.3rem;
            margin-top: 1em;
            margin-bottom: 1em;
            padding-top: 0.7em;
            padding-bottom: 0.7em;
            color: var(--color-additional);
            font-weight: bold;
            width: 17rem;           
            border:none;
            border-radius: 0.2em;
            background-color: var(--color-primary);
            box-shadow: inset 0 0 0 0 var(--color-secondary);
            transition: ease-in-out 150ms;

            &:hover {
                cursor: pointer;
                box-shadow: inset 17rem 0 0 0 var(--color-secondary);
                color: var(--color-additional);                    
            }
        }

    }
    
}

@media screen and (min-width: 1000px) {
.aboutUs{
    
    

    .aboutus-container{
    
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: space-between;
        max-width: 90%;
        padding-bottom: 2em;
        .text-container{
            line-height: 1.3em;
            font-size: 1rem;
            width: 45%;
            p{
                margin-top: 0.2em;
                margin-bottom: 1em;
            }
        }
        .image-container{
            width: 45%;
        }
    }
}


}